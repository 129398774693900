import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import {
  PrototypeContainer,
  PrototypeFormContainer,
  QuestionVisibilityButton,
  ToggleButtonText,
} from './PrototypeStyle';
import { MainContainer } from '../StyledUtils';
import { PrototypeLinkComponent } from './PrototypeLinkComponent';
import { fetchSurveyByLink, recordResponseByLink, editSurveyStatus, customEvents } from '../../services/linkService';
import { Iparams, ISurvey, OptionItem, response, RuleItem } from '../../types/survey';
import queryString from 'query-string';
import { Oval } from 'react-loader-spinner';
import { checkRange, formatResponseData, replacePlaceholderText } from '../HelperUtil';
import { usePrefillAnswer } from '../../hooks/usePrefillAnswer';
import { allRatingQuestions } from '../../static/staticData';
import { getPrototypeLink, isMobile, isValidImageURL } from '../../utils/helper.util';

import { ReactComponent as HideImg } from '../../images/hide-icon.svg';
import { ReactComponent as ShowImg } from '../../images/show-icon.svg';

const initialState: response = { survey_input: '', scale_value: -1, responses: [] };

const PrototypeComponent = () => {
  const [activeIdx, setActiveIdx] = useState<number>(-1);
  const [showCard, setShowCard] = useState(false);
  const [hideDemo, setHideDemo] = useState(false);
  const [progressBarWidth, setProgressBarWidth] = useState(0);

  const [surveyData, setSurveyData] = useState<ISurvey>();
  const [isComplete, setComplete] = useState(false);
  const [responseTime, setResponseTime] = React.useState<number>(0);

  const [isLoading, setIsLoading] = useState(true);

  const [showQuestion, setShowQuestion] = useState(false);

  const [surveyResponse, setSurveyResponse] = useState(initialState);
  const params: Iparams = useParams();
  const { link } = params;

  let lang_code = '';
  let user_id = '';
  let preview = '';
  const prefill_answers = {};
  const placeholder_values = {};

  const queryData = queryString.parse(location.search);

  if (queryData) {
    for (const i in queryData) {
      if (i === 'lang') {
        lang_code = queryData.lang as string;
      } else if (i === 'user_id') {
        if (queryData && queryData.user_id && typeof queryData.user_id == 'string') {
          user_id = encodeURIComponent(queryData.user_id);
        }
      } else if (i.startsWith('q')) {
        prefill_answers[i] = queryData[i];
      }
      // if it's a preview
      else if (i === 'preview') {
        preview = queryData.preview as string;
      } else {
        placeholder_values[i] = queryData[i];
      }
    }
  }

  const nextSurveyScreen = async (
    question_id: string,
    question_order: number,
    question_type: string,
    rule_sets: Array<RuleItem>,
    survey_input?: string,
    scale_value?: number,
    responses?: Array<OptionItem>,
    prefill = false,
  ) => {
    if (!surveyData) return null;
    const { questions, survey, auth_token } = surveyData;
    const { survey_id } = survey;

    const { response, field_name } = formatResponseData(question_type, survey_input, scale_value, responses);

    //check if last answer is complete
    let last_response_complete = false;

    const is_last_question = questions.length === activeIdx + 1 ? true : false;

    if (response !== undefined) {
      const user_response = { [field_name]: response };
      if ((question_type === 'multi_select_feedback' || question_type === 'single_select_feedback') && survey_input) {
        user_response.survey_input = survey_input;
      }

      const response_obj = {
        survey_id,
        question_id,
        question_order,
        question_type,
        link_user_id: user_id,
        response_duration: new Date().getTime() - responseTime,
        ...user_response,
      };

      if (!prefill || is_last_question) {
        let results: any = '';
        await processPrefillQueue(link, user_id);

        // for preview, api call will not happen
        if (preview) {
          results = { data: { valid_response: true } };
        } else {
          results = await recordResponseByLink(response_obj, link, auth_token, user_id);
        }

        if (results && results.data && results.data.valid_response) {
          setComplete(true);
          last_response_complete = true;
        }
      } else {
        setPendingResponses([...pendingResponses, response_obj]);
        setComplete(true);
        last_response_complete = true;
      }
    }

    const handleRules = async (skip_to: number) => {
      if (skip_to === -1 || questions.length <= skip_to - 1) {
        setProgressBarWidth(100);
        setTimeout(() => {
          setShowCard(true);
          setActiveIdx(-1);
        }, 500);

        if (isComplete || last_response_complete) {
          await editSurveyStatus(survey_id, auth_token, user_id);
        }
      } else if (questions.length > skip_to - 1) {
        setProgressBarWidth((activeIdx + 1) * (100 / questions.length));
        setTimeout(() => {
          setActiveIdx(skip_to - 1);
          setShowCard(false);
        }, 500);
      } else {
        setHideDemo(true);
      }
    };

    // console.log('----------------------------------');
    // console.log('responses --', responses);
    // console.log('question_order --', question_order);
    // console.log('question_type --', question_type);
    // console.log('survey_input --', survey_input);
    // console.log('scale_value --', scale_value);
    // console.log('rule_sets --', rule_sets);
    // console.log('----------------------------------');

    if (rule_sets && rule_sets.length > 0) {
      for (let i = 0; i < rule_sets.length; i++) {
        const { rule_type, option, options, skip_to } = rule_sets[i];

        if (allRatingQuestions.includes(question_type)) {
          if (
            scale_value !== undefined &&
            option !== undefined &&
            checkRange(question_type, scale_value) &&
            checkRange(question_type, option) &&
            ((rule_type === 'eq' && scale_value === option) ||
              (rule_type === 'neq' && scale_value !== option) ||
              (rule_type === 'lt' && scale_value < option) ||
              (rule_type === 'lte' && scale_value <= option) ||
              (rule_type === 'gt' && scale_value > option) ||
              (rule_type === 'gte' && scale_value >= option))
          ) {
            handleRules(skip_to);
            return;
          }
        }

        if (question_type === 'single_select_feedback') {
          if (options && options[0] && responses && rule_type === 'in_list_exact') {
            if (responses.find((o) => o.option_text === options[0])) {
              handleRules(skip_to);
              return;
            }
          }
        }

        if (options && responses && question_type === 'multi_select_feedback') {
          const response_arr = responses.map((r) => r.option_text);
          if (rule_type === 'in_list_exact') {
            const check_equal = _.isEqual(_.sortBy(options), _.sortBy(response_arr));

            if (check_equal) {
              handleRules(skip_to);
              return;
            }
          }

          if (rule_type === 'in_list_once') {
            for (let i = 0; i < response_arr.length; i++) {
              if (options.includes(response_arr[i])) {
                handleRules(skip_to);
                return;
              }
            }
          }
        }

        if (options && survey_input && question_type === 'data_collection' && rule_type === 'in_list_once') {
          if (options.includes(survey_input.trim())) {
            handleRules(skip_to);
            return;
          }
        }

        if (rule_type === 'submit') {
          handleRules(skip_to);
          return;
        }

        if (i + 1 === rule_sets.length) {
          handleRules(question_order + 1);
          return;
        }
      }
    } else {
      if (questions.length > activeIdx + 1) {
        // progressbarwidth for questions except last one
        setProgressBarWidth((activeIdx + 1) * (100 / questions.length));
        setTimeout(() => {
          setActiveIdx(activeIdx + 1);
          setShowCard(false);
        }, 500);
      } else if (questions.length === activeIdx + 1) {
        // progressbarwidth for last question
        setProgressBarWidth(100);
        setTimeout(() => {
          setShowCard(true);
        });

        // for preview, api call will not happen
        if (!preview && (isComplete || last_response_complete)) {
          await editSurveyStatus(survey_id, auth_token, user_id);
        }
      } else {
        setHideDemo(true);
      }
    }
  };

  const { processPrefillQueue, handlePrefillAnswer, pendingResponses, setPendingResponses } = usePrefillAnswer({
    surveyData,
    setIsLoading,
    prefill_answers,
    nextSurveyScreen,
  });

  // when the next question will appear, the reset will happen to make the sync between progressbarwidth and reseting values
  useEffect(() => {
    if (activeIdx > -1) {
      setSurveyResponse({ survey_input: '', scale_value: -1, responses: [] });
      setResponseTime(new Date().getTime());
      if (prefill_answers[`q${activeIdx + 1}`]) {
        handlePrefillAnswer(activeIdx + 1);
      } else {
        setIsLoading(false);
      }
    } else {
      processPrefillQueue(link, user_id);
    }
    customEvents(user_id, 'activeId changed', { activeIdx });
  }, [activeIdx]);

  const fetchSurveys = async () => {
    const surveyItem = await fetchSurveyByLink(link, lang_code, user_id, preview);
    if (surveyItem) {
      if (surveyItem && surveyItem.message) {
        window.location.href = '/complete';
        return;
      }

      const { auth_token, questions } = surveyItem;
      localStorage.setItem('auth_token', auth_token);
      if (questions && questions[0]) {
        const { prototype_link } = questions[0];
        if (!prototype_link) {
          setShowQuestion(true);
        }
      }
      setSurveyData(surveyItem);
    }
  };

  useEffect(() => {
    if (surveyData) {
      const starting_from =
        surveyData.survey && surveyData.survey.starting_from > 0 ? surveyData.survey.starting_from - 1 : 0;
      setActiveIdx(starting_from);
      customEvents(user_id, 'surveyData changed');
    }
  }, [surveyData]);

  useEffect(() => {
    customEvents(user_id, 'survey init');
    fetchSurveys();
  }, []);

  if (!surveyData) return null;

  const { questions, survey } = surveyData;

  if (isLoading) {
    const { survey_theme } = survey;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: survey_theme && survey_theme.bg_color ? survey_theme.bg_color : 'black',
        }}
      >
        <Oval
          ariaLabel="loading-indicator"
          height={40}
          width={40}
          strokeWidth={5}
          strokeWidthSecondary={5}
          color={survey_theme && survey_theme.highlight_color ? survey_theme.highlight_color : 'black'}
          secondaryColor={survey_theme && survey_theme.text_color ? `${survey_theme.text_color}50` : 'grey'}
          wrapperStyle={{ marginTop: '45vh' }}
        />
      </div>
    );
  } else {
    const {
      show_thanks_card,
      thanks_card_desc,
      thanks_card_title,
      show_blitz_logo,
      show_progress_bar,
      survey_theme,
      background_image,
      survey_id,
    } = survey;
    const thanksCard = {
      show_thanks_card,
      thanks_card_desc,
      thanks_card_title,
    };
    const question = questions[activeIdx];

    if (question) {
      question.question_text = replacePlaceholderText(placeholder_values, question.question_text);
    }

    const { prototype_link = '' } = question;
    const show_prototype_link = prototype_link && ((isMobile() && !showQuestion) || !isMobile());

    return (
      <PrototypeContainer>
        {show_prototype_link && (
          <div style={{ flex: '7 1' }}>
            {isValidImageURL(prototype_link) ? (
              <img
                src={prototype_link}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  display: 'block',
                  margin: 'auto',
                  maxHeight: '100vh',
                }}
              />
            ) : (
              <div style={{ height: '100vh' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src={getPrototypeLink(prototype_link)}
                  allowFullScreen
                  frameBorder={0}
                ></iframe>
              </div>
            )}
          </div>
        )}

        {showQuestion && (
          <MainContainer
            style={{
              backgroundColor: `${survey_theme.bg_color}`,
              backgroundImage: `url(${background_image})`,
              width: !isMobile() && prototype_link ? '32vw' : '100%',
              flex: '3 1',
            }}
          >
            <PrototypeFormContainer
              is_full={!prototype_link}
              style={{ width: isMobile() ? '100%' : prototype_link ? '40vw' : '65vw' }}
            >
              {!hideDemo && question && (
                <div>
                  <PrototypeLinkComponent
                    surveyId={survey_id}
                    question={question}
                    thanksCard={thanksCard}
                    showCard={showCard}
                    nextScreen={nextSurveyScreen}
                    theme={survey_theme}
                    showLogo={show_blitz_logo}
                    showProgressBar={show_progress_bar}
                    progressBarWidth={progressBarWidth}
                    languageCode={lang_code}
                    surveyResponse={surveyResponse}
                    setSurveyResponse={setSurveyResponse}
                  />
                </div>
              )}

              {/* handle case when question is null and thanks card has to be shown */}
              {!hideDemo && activeIdx === -1 && showCard && (
                <PrototypeLinkComponent
                  surveyId={survey_id}
                  question={question}
                  thanksCard={thanksCard}
                  showCard={showCard}
                  nextScreen={nextSurveyScreen}
                  theme={survey_theme}
                  showLogo={show_blitz_logo}
                  showProgressBar={show_progress_bar}
                  progressBarWidth={progressBarWidth}
                  languageCode={lang_code}
                  surveyResponse={surveyResponse}
                  setSurveyResponse={setSurveyResponse}
                />
              )}
            </PrototypeFormContainer>
          </MainContainer>
        )}

        {prototype_link && (
          <QuestionVisibilityButton
            style={{
              backgroundColor: showQuestion ? '#fff' : survey_theme.highlight_color,
              border: showQuestion ? `1px solid #00000026` : 0,
            }}
            onClick={() => setShowQuestion(!showQuestion)}
          >
            {showQuestion ? <HideImg /> : <ShowImg />}
            <ToggleButtonText style={{ color: showQuestion ? '#000' : survey_theme.cta_text_color }}>
              {showQuestion ? 'Click here to see the designs' : 'Show questions'}
            </ToggleButtonText>
          </QuestionVisibilityButton>
        )}
      </PrototypeContainer>
    );
  }
};

export default PrototypeComponent;
